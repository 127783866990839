<template>
	<div v-if="canSeeName">
		<v-tooltip v-if="canRename && simpleButtonDisplay && !listTileDisplay && !singleTileDisplay" bottom>
			<template v-slot:activator="{ on }">
				<v-btn ref="renameClick" icon @click.native="doShowRenameNodeDialog" v-on="on">
					<v-icon color="primary" class="mr-1">mdi-form-textbox</v-icon>
				</v-btn>
			</template>
			<span v-t="'documents.rename_item'" />
		</v-tooltip>
		<v-list-tile
				v-else-if="listTileDisplay && !singleTileDisplay && canRename"
				ref="renameClick"
				avatar
				@click.stop="doShowRenameNodeDialog">
			<v-list-tile-avatar>
				<v-icon color="primary">mdi-form-textbox</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title v-t="'documents.rename_item'" />
			</v-list-tile-content>
		</v-list-tile>
		<v-list-tile v-else-if="singleTileDisplay">
			<v-list-tile-content v-if="!listTileDisplay">
				<v-badge color="success" overlap left class="ml-2">
					<template v-if="isValidated" v-slot:badge>
						<v-icon class="mr-3" color="grey lighten-1">check</v-icon>
					</template>
					<v-list-tile-title>
						{{ node.name }}
					</v-list-tile-title>
				</v-badge>
			</v-list-tile-content>
			<v-list-tile-content v-else>
				<v-list-tile-title>{{ $t('name') }}</v-list-tile-title>
				<v-list-tile-sub-title>
					{{ node.name }}
				</v-list-tile-sub-title>
			</v-list-tile-content>
			<v-list-tile-action v-if="canRename">
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-btn ref="renameClick" icon v-on="on" @click.native="doShowRenameNodeDialog">
							<v-icon color="grey lighten-1">edit</v-icon>
						</v-btn>
					</template>
					<span v-t="'documents.rename_item'" />
				</v-tooltip>
			</v-list-tile-action>
		</v-list-tile>
		<RenameDialog v-if="showRenameDialog" v-model="node" :items="items" @close="doCloseRenameDialog" />
		<FolderCreator v-if="showRenameDialogReplicableFolder" v-model="node" is-renaming @close="doCloseRenameDialog()"></FolderCreator>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import DocumentActionModuleGuard from '@/mixins/ModulesGuards/Documents/Actions/DocumentActionModuleGuard'
import NodeCreationMixin from '@/mixins/Documents/NodeCreationMixin'

const name = 'RenameButton'

export default {
	name,
	components: {
    RenameDialog: () => ({
        component: import('@/components/Documents/Actions/Rename/RenameDialog')
    }),
	FolderCreator: () => ({
			component: import('@/components/Documents/Actions/Add/FolderCreator')
	})
},
	mixins: [DocumentActionModuleGuard, NodeCreationMixin],
	props: {
		openWithEvent: {
			default: false,
			required: false,
			type: Boolean
		},
		singleTileDisplay: {
			type: Boolean,
			required: false,
			default: false
		},
		value: {
			type: [Object, Array],
			required: false,
			default: () => []
		},
		items: {
			default: () => [],
			required: false,
			type: Array
		}
	},
	data: function () {
		return {
			showRenameDialog: false,
			showRenameDialogReplicableFolder: false
		}
	},
	computed: {
		...mapState({
			isGuest: state => state.company.userRoles.isGuest,
			pauseKeyboardListening: state => state.pauseKeyboardListening,
			currentDialog: state => state.dialogs.current,
		}),
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		nodes: function () {
			return Array.isArray(this.value) ? this.value : [this.value] || []
		},
		node: {
			get: function () {
				return this.nodes.length == 1 ? this.nodes[0] : null
			},
			set: function (value) {
				this.model = value
			}
		},
		parentFolder: function () {
			return this.node ? this.node.parent : null
		},
		canSeeName: function () {
			return this.node != null
		},
		canRename: function () {
			let result = false
			if (this.node && !this.node.is_deleted) {
				if (this.node.is_folder) {
					result = !this.node.is_root && !this.node.is_trash && ((!this.node.is_default && !this.node.is_readonly) || this.isReplicableFolder)
				} else {
					result = true
				}
				result = result && !this.parentFolder?.is_readonly
			}
			result = result && !this.isGuest
			return result
		},
		isValidated: function () {
			return this.hasOne && this.currentVersion?.validated_at != null
		},
		currentVersion: function () {
			let result = null
			if (!this.hasMany && this.node.versions) {
				result = this.node.latestVersion ? this.node.latestVersion : null
			}
			return result
		},
		hasOne: function () {
			return !this.hasMany && (this.value || (Array.isArray(this.value) && this.value.length == 0))
		},
		isReplicableFolder: function() {
			return this.node ? this.node.is_manually_replicable : null
		}
	},
	methods: {
		...mapActions([
			'setPauseKeyboardListening',
			'setPreventDefaultKeyboardEvents',
		]),
		...mapMutations({
			setCurrentDialog: 'dialogs/setCurrent'
		}),
		getKeyboardEventsActionsMapping: function () {
			return [
				{
					actions: [
						{
							key: this.keyboard.F2,
							os: [this.OS.WINDOWS, this.OS.LINUX],
							action: this.doShowRenameNodeDialog
						},
						{
							key: this.keyboard.enter,
							os: [this.OS.MACOS],
							action: this.doShowRenameNodeDialog
						},
					]
				}
			]
		},
		doShowRenameNodeDialog: function (event) {
			if (this.pauseKeyboardListening) {
				return;
			}
			if (this.currentDialog !== null && this.currentDialog !== name) {
				return;
			}
			if (event instanceof KeyboardEvent && event.target !== document.body) {
				return;
			}
			this.setPreventDefaultKeyboardEvents(false)
			this.setPauseKeyboardListening(true)
			if (this.isReplicableFolder) {
				this.showRenameDialogReplicableFolder = true
			} else {
				this.showRenameDialog = true
			}
			this.setCurrentDialog(name)
		},
		doCloseRenameDialog: function () {
			this.setPauseKeyboardListening(false)
			this.setPreventDefaultKeyboardEvents(true)
			this.setCurrentDialog(null)
			this.showRenameDialog = false
			this.showRenameDialogReplicableFolder = false
		}
	}
}
</script>

<style scoped>
.v-list__tile__title {
	max-width: 300px;
}
</style>
