import { getTruncatedFilename, getFileChecksum } from '@/helpers/files'

import DocumentManagerService from '@/services/Documents/DocumentsManagerService'
import DocumentVersionsService from '@/services/Documents/DocumentVersionsService'
import DocumentsManagerService from '@/services/Documents/DocumentsManagerService'

let UserHasBeenWarned = false

export default {
	data: function () {
		return {
			itemsUploaderMixinMaxFilenameLength: 255,
			documentService: DocumentManagerService,
			documentVersionService: DocumentVersionsService,
			documentsManagerService: DocumentsManagerService
		}
	},
	methods: {
		___uploadItems: function (targetedFolder, items) {
			let result = Promise.resolve()
			this.appEventBus.emit(this.appEvents.UPLOAD_STARTED)
			UserHasBeenWarned = false
			const entries = items && items instanceof FileList ? Array.from(items) : items
			if (entries && Array.isArray(entries)) {
				const isRoot = targetedFolder.is_root
				result = Promise.all(
					entries.map(item => {
						let treatedEntry = Promise.resolve()
						if (item instanceof File) {
							treatedEntry = this.___handleFileObjectEntry(targetedFolder, isRoot, item)
						} else if (item?.isFile) {
							treatedEntry = this.___handleIsFileEntry(targetedFolder, isRoot, item)
						} else if (item?.isDirectory) {
							treatedEntry = this.___handleDirectoryEntry(targetedFolder, item)
						}
						return treatedEntry
					})
				)
			}
			return result.finally(() => {
				targetedFolder.mergeChildren = undefined
				this.appEventBus.emit(this.appEvents.UPLOAD_ENDED)
			})
		},
		___handleFileObjectEntry: function (targetedFolder, isRoot, item) {
			let result = Promise.resolve()
			if (!isRoot) {
				result = this.___createDocument(targetedFolder, item)
			} else if (!UserHasBeenWarned) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('documents.errors.no_document_on_root_folder'))
				UserHasBeenWarned = true
			}
			return result
		},
		___handleIsFileEntry: function (targetedFolder, isRoot, item) {
			let result = Promise.resolve()
			if (!isRoot) {
				result = new Promise(resolve => {
					item.file(
						file => {
							resolve(this.___createDocument(targetedFolder, file))
						},
						error => {
							throw error
						}
					)
				})
			} else if (!UserHasBeenWarned) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('documents.errors.no_document_on_root_folder'))
				UserHasBeenWarned = true
			}
			return result
		},
		async __readEntriesPromise (directoryReader) {
			return await new Promise((resolve, reject) => {
				directoryReader.readEntries(resolve, reject)
			})
		},
		async __readAllDirectoryEntries (directoryReader) {
			let entries = []
			let readEntries = await this.__readEntriesPromise(directoryReader)
			while (readEntries.length > 0) {
				entries = entries.concat(readEntries)
				readEntries = await this.__readEntriesPromise(directoryReader)
			}
			return entries
		},
		___handleDirectoryEntry: function (targetedFolder, item) {
			const directoryReader = item.createReader()
			return this.___createFolder(targetedFolder, { name: item.name, isFolder: true }).then(createdFolder => {
				return this.__readAllDirectoryEntries(directoryReader).then(entries => this.___uploadItems(createdFolder, entries))
			})
		},
		___createDocument: function (targetedFolder, rawDocument, retry = true) {
			let result = Promise.resolve()
			if (targetedFolder != null && targetedFolder.id) {
				result = this.___getUploadOptions(targetedFolder, rawDocument).then(options => {
					let subResult = Promise.resolve()
					if (options.continue) {
						subResult = this.___createDocumentOrVersion(targetedFolder, rawDocument, options.merge, options.sibblingNodeId)
							.catch(error => {
								return this.___retryUploadDocumentOnError(error, targetedFolder, rawDocument, false)
							})
							.then(documentVersion => {
								return getFileChecksum(rawDocument).then(fileChecksum => {
									return this.___validateChecksum(documentVersion, fileChecksum).catch(error => {
										const documentToDelete = this.documentService.findNodeInStructure(documentVersion.document_id)
										return this.___deleteDocumentOrVersion(documentToDelete, documentVersion).finally(() => {
											return this.___retryUploadDocumentOnError(error, targetedFolder, rawDocument, retry)
										})
									})
								})
							})
					}
					return subResult
				})
			}
			return result
		},
		___createFolder: function (targetedFolder, rawFolder) {
			let result = Promise.resolve()
			if (targetedFolder != null && targetedFolder.id) {
				result = this.___getUploadOptions(targetedFolder, rawFolder).then(options => {
					let subResult = Promise.resolve()
					if (options.continue) {
						if (options.merge && options.sibblingNodeId) {
							subResult = this.documentService.findNodeInStructure(options.sibblingNodeId)
							subResult.mergeChildren = true
						} else {
							subResult = this.documentService.createFolder(this.vendorId, targetedFolder, { name: options.name })
						}
					}
					return subResult
				})
			}
			return result
		},

		__moveNode: function (targetedFolder, rawFolder, moveAction) {
			let result = Promise.resolve()
			result = this.___getUploadOptions(targetedFolder, rawFolder).then(options => {
				if (options.continue) {
					const oldParent = rawFolder.parent
					if (oldParent) {
						oldParent.remove(rawFolder)
					}
					if (options.merge) {
						targetedFolder = this.___getExistingNodeWithSameName(targetedFolder, rawFolder.name)
						this.___mergeFolders(targetedFolder, rawFolder, moveAction)
					} else {
						targetedFolder.add(rawFolder)
						moveAction(this.vendorId, oldParent.id, rawFolder, null, { folder_id: targetedFolder.id }).catch(error => {
							targetedFolder.remove(rawFolder)
							oldParent.add(rawFolder)
							this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, error.message)
						})
					}
				}
			})
			return result
		},

		___loadFolderContent (rawFolder) {
			let getFolderContent = Promise.resolve()
			if (!rawFolder.children || rawFolder.children.length == 0) {
				const parameters = {
					filters: {
						with_content: true
					},
					fields: ['counter', 'folders', 'folders.counter', 'folders.has_sub_folders', 'has_sub_folders', 'documents']
				}
				getFolderContent = this.documentService.getFolder(this.vendorId, rawFolder.id, parameters, true, false)
			}
			return getFolderContent
		},

		___mergeChild (targetedFolder, nodeChild) {
			this.___getUploadOptions(targetedFolder, nodeChild).then(options => {
				if (options.continue && options.merge && options.sibblingNodeId) {
					let subResult = this.documentService.findNodeInStructure(options.sibblingNodeId)
					subResult.mergeChildren = true
					this.___mergeFolders(subResult, nodeChild)
				} else {
					targetedFolder.add(nodeChild)
				}
			})
		},

		___mergeFolders: function (targetedFolder, rawFolder) {
			const oldParent = rawFolder.parent
			targetedFolder.mergeChildren = true
			if (oldParent) {
				oldParent.remove(rawFolder)
			}
			let movedNodes = []
			const getFolderContent = this.___loadFolderContent(rawFolder)
			getFolderContent.then(() => {
				if (rawFolder.children.length > 0) {
					let result = Promise.all(rawFolder.children.map(nodeChild => {
						let action = Promise.resolve()
						let moveAction = null
						if (nodeChild.is_folder) {
							nodeChild.isFolder = true
							moveAction = this.documentService.updateFolderFolder
							this.___mergeChild(targetedFolder, nodeChild)
						} else if (nodeChild.is_document) {
							targetedFolder.add(nodeChild)
							moveAction = this.documentService.updateFolderDocument
						}
						movedNodes.push(nodeChild)
						action = moveAction(this.vendorId, rawFolder.id, nodeChild, null, { folder_id: targetedFolder.id })
						return action
					})
					)
					result.then(() => {
						this.documentService.deleteFolder(this.vendorId, rawFolder)
					}).catch(error => {
						movedNodes.forEach(nodeToRemove => {
							targetedFolder.remove(nodeToRemove)
						})
						oldParent.add(rawFolder)
						this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, error.message)
					})
				} else {
					targetedFolder.add(rawFolder)
				}
			})
		},
		___normalizeUnicodeEncoding: function (text) {
			return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
		},
		___getExistingNodeWithSameName: function (targetedFolder, name) {
			let result = null;
			let normalizedName = this.___normalizeUnicodeEncoding(name).toLowerCase();

			if (targetedFolder.children) {
				result = targetedFolder.children.find(siblingNode => {
					let normalizedSiblingName = this.___normalizeUnicodeEncoding(siblingNode.name).toLowerCase();
					return normalizedSiblingName.trim() === normalizedName.trim();
				});
			}

			return result;
		},
		___getUploadOptions: function (targetedFolder, nodeToUpload) {
			// eslint-disable-next-line sonarjs/cognitive-complexity
			return new Promise(resolve => {
				let getFolderContent = Promise.resolve()
				if (!targetedFolder.children || targetedFolder.children.length == 0) {
					const parameters = {
						filters: {
							with_content: true
						},
						fields: ['counter', 'folders', 'folders.counter', 'folders.has_sub_folders', 'folders.vendor', 'has_sub_folders', 'vendor', 'documents']
					}
					getFolderContent = this.documentService.getFolder(this.vendorId, targetedFolder.id, parameters, true, false)
				}
				getFolderContent.then(() => {
					const nodeName = getTruncatedFilename(nodeToUpload.name)
					const existingNodeWithSameName = this.___getExistingNodeWithSameName(targetedFolder, nodeName)
					let doContinue = true
					let merge = (existingNodeWithSameName && targetedFolder.mergeChildren) ? true : false
					let sibblingNodeId = existingNodeWithSameName ? existingNodeWithSameName.id : null
					if (existingNodeWithSameName && !merge && !nodeToUpload?.is_manually_replicable) {
						existingNodeWithSameName.isFolder = nodeToUpload.isFolder
						this.___promptForMerge(existingNodeWithSameName).then(answer => {
							if (answer == 'Cancelled') {
								doContinue = false
							} else {
								merge = answer
							}
							resolve({
								name: nodeName,
								continue: doContinue,
								merge: merge,
								sibblingNodeId: sibblingNodeId
							})
						})
					} else {
						resolve({
							name: nodeName,
							continue: doContinue,
							merge: merge,
							sibblingNodeId: sibblingNodeId
						})
					}
				})
			})
		},
		___promptForMerge: function (nodeToUpload) {
			return (async () => {
				return await new Promise(resolve => {
					this.appService.openDialog({
						attrs: {
							value: nodeToUpload
						},
						component: () => import('@/components/Documents/Upload/ShouldMergeDialog'),
						listeners: {
							close: function () {
								resolve('Cancelled')
							},
							input: function (event) {
								resolve(event)
							}
						}
					})
				})
			})()
		},
		___informOfChecksumFailure: function (nodeToUpload) {
			return (async () => {
				return await new Promise(resolve => {
					this.appService.openDialog({
						attrs: {
							value: nodeToUpload
						},
						component: () => import('@/components/Documents/Upload/ChecksumFailedDialog'),
						listeners: {
							close: function () {
								resolve('Cancelled')
							},
							input: function (event) {
								resolve(event)
							}
						}
					})
				})
			})()
		},
		___createDocumentOrVersion: function (targetedFolder, rawDocument, merge, documentId = null) {
			let result
			if (merge) {
				result = this.documentVersionService.createDocumentVersion(this.vendorId, documentId, rawDocument)
			} else {
				result = this.documentService.createFolderDocument(this.vendorId, targetedFolder.id, rawDocument).then(createdDocument => {
					return this.documentVersionService.getDocumentVersions(this.vendorId, createdDocument).then(() => {
						return createdDocument.latestVersion
					})
				})
			}
			return result
		},
		___deleteDocumentOrVersion: function (currentDocument, documentVersion) {
			let result = null
			const previousDocumentVersion = currentDocument.previousVersion(documentVersion)
			if (previousDocumentVersion) {
				result = this.documentVersionService.deleteDocumentVersion(this.vendorId, currentDocument.id, documentVersion)
			} else {
				result = this.documentService.deleteDocument(this.vendorId, currentDocument, { forceDelete: true })
			}
			return result
		},
		___retryUploadDocumentOnError: function (error, targetedFolder, rawDocument, retry = false) {
			if (!retry) {
				throw error
			}
			return this.___createDocument(targetedFolder, rawDocument, false)
		},
		___validateChecksum: function (documentVersion, fileChecksum) {
			const document = this.documentService.findNodeInStructure(documentVersion.document_id)
			return this.documentVersionService
				.getDocumentVersionChecksum(this.vendorId, document.id, document.latestVersion.id, { file_signature: fileChecksum })
				.then(() => {
					return document.latestVersion
				})
		}
	}
}
